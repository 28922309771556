<template>
  <div class="content-box">
    <img class="down-bg" src="@/assets/registeredAgent/down-bg.png" alt="" />
    <div class="submit-btn anima" @click="downApp">立即下载APP</div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      appLogoUrl: "",
      appDownloadUrl: "",
      appName: "",
    };
  },
  mounted() {},
  methods: {
    /* 判断用户手机为安卓还是iphone */
    checkPhone() {
      // console.log('navigator', navigator)
      let self = this;
      let agent = navigator.userAgent || navigator.vendor || window.opera;
      if (agent != null) {
        let agentName = agent.toLowerCase();
        if (/android/i.test(agentName)) {
          self.isAndroid = true;
          console.log("安卓机", self.isAndroid);
          window.location.href = `https://api.zfshoufutong.com/api/resource/update/packages/package?appType=1`;
        } else if (/iphone/i.test(agentName)) {
          self.isIOS = true;
          console.log("苹果机", self.isIOS);
          // Toast("暂未开放，敬请期待");
          window.location.href = `https://apps.apple.com/cn/app/6447540750`;
        }
      }
    },
    //点击下载应用
    downApp() {
      this.checkPhone();
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.body.setAttribute("style", "background-color:#fff");
    });
  },
  //实例销毁之前钩子--移除body 标签的属性style
  beforeDestroy() {
    document.body.removeAttribute("style");
  },
};
</script>
<style lang="less" scoped>
.content-box {
  position: relative;
  background: #fe5732;
  .down-bg {
    width: 100%;
    height: 100%;
  }

  .submit-btn {
    position: fixed;
    z-index: 1;
    // top: 1217px;
    bottom: 50px;
    width: 610px;
    height: 105px;
    line-height: 105px;
    left: 50%;
    transform: translate(-50%, 0);
    text-align: center;
    background-color: #ff4741;
    border-radius: 20px;
    font-size: 35px;
    font-weight: bold;
    color: #322e20;
    background: linear-gradient(270deg, #ffcf6f 2%, #ffcd45 99%);
    // margin-bottom: 165px;
  }
  .anima {
    animation-name: downBbut; // 动画名称
    animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
    animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
    animation-delay: 0s; // 动画延迟时间
    animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
    animation-duration: 1.5s; // 动画完成时间
  }

  @keyframes downBbut {
    0% {
      transform: translate(-50%, 0) scale(1);
    }
    25% {
      transform: translate(-50%, 0) scale(0.9);
    }
    50% {
      transform: translate(-50%, 0) scale(0.85);
    }
    75% {
      transform: translate(-50%, 0) scale(0.9);
    }
    100% {
      transform: translate(-50%, 0) scale(1);
    }
  }
}
</style>
